<template>
    <div class="loader_area3" v-if="loader"><quote-loader/></div>
    <div class="profile_notes" v-else>
        <div class="title">
            <h3>Total Rewards: {{ totalPoints }}
                <button type="button" class="add_btn pointer ml-auto" @click="addReward = true"><i class="fas fa-plus"></i>Add Reward</button>
                <button type="button" class="add_btn pointer" @click="redeem = true">Redeem</button>
            </h3>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" :disabled="rewardLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
                <button :class="selectedRewards.length ? 'delete active' : 'delete'" @click="hendleDeleteReward">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
        <div class="notes_wpr">
            <ul class="card_listing" v-if="rewards.total">
                <li v-for="(reward, r) of rewards.data" :key="r">
                    <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="reward">
                        <h4 @click="actionHandler(reward)" class="pointer"><img src="@/assets/images/gift.svg">{{ reward.points }}</h4>
                        <div class="card_content">
                            <h5>Source:</h5>
                            <p>{{ reward.source_name }}</p>
                            <h5>Reason:</h5>
                            <p>{{ reward.reason ? reward.reason : '' }}</p>
                        </div>
                        <div class="card_footer">
                            <!-- <h5>Created on:</h5> -->
                            <span class="date">
                                {{ moment.utc(reward.created_at).local().format('ll | LT')}}
                            </span>
                        </div>
                        <label :for="`switch-${r}`" class="checkbox">
                        <input type="checkbox" :id="`switch-${r}`" :value="reward.id" v-model="selectedRewards" hidden>
                        <span><i class="fas fa-check"></i></span>
                        </label>
                    </profile-card>
                </li>
            </ul>
            <div class="empty_list" v-if="rewards.total == 0 && !loader">
                <img src="@/assets/images/empty_state.svg">
                <h4>No Records Found!</h4>
            </div>
        </div>
        <div class="pagination" v-show="rewards.total">
            <pagination v-model="params.page" :pages="rewards.last_page" @update:modelValue="handlePagination" />
        </div>
        <div v-if="rewardEdit" class="edit_modal" @click.self="rewardEdit = false">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Edit Points<button class="close_btn" @click="rewardEdit = false"><i class="fas fa-times"></i></button></h2>
                    <Form @submit="handleEditReward" v-slot='{ errors }'>
                        <div class="form_content">
                            <div class="form_grp mb-5">
                                <div class="group_item">
                                    <label class="input_label">Edit Points</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.edit_reward }">
                                        <Field autocomplete="off" type="number" name="edit_reward" v-model="editForm.points" placeholder="0" rules="required|numeric" />
                                    </div>
                                    <ErrorMessage name="edit_reward" class="text-danger" />
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button :disabled="rewardLoader" class="btn cancel_btn" @click="rewardEdit = false">Cancel</button>
                                <button :disabled="rewardLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="rewardLoader"></i> {{ rewardLoader ? ' Saving' : 'Save'}}</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <add-reward v-model="addReward" :contact="contact" />
        <redeem-component v-model="redeem" :contact="contact" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const AddReward = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/AddReward'))
    const RedeemComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/Redeem'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default{
        name: 'ProfileReward',

        data () {
            return{
                rewardEdit: false,
                redeem: false,
                selectedRewards: [],
                actions: [
                    {
                        icon: 'fa-edit',
                        label: 'Edit',
                    },
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                },
                isTyping: false,
                moment,
                editForm: {
                    id: '',
                    points: 0,
                },
                addReward: false,
            }
        },

        props: {
            contact: Object,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getContactReward(vm.params);
            },

            'params.search' () {
                const vm = this;

                vm.isTyping = true;
                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactReward(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactReward(vm.params);
                        }
                    }
                }
            },

            rewards () {
                const vm = this;
                vm.selectedRewards = [];
            }
        },

        components:{
            Form,
            Field,
            ErrorMessage,
            AddReward,
            ProfileCard,
            RedeemComponent,
        },

        computed: mapState({
            rewards: state => state.contactModule.contactRewards,
            totalPoints: state => state.contactModule.rewardsTotalPoints,
            rewardLoader: state => state.contactModule.rewardLoader,
            loader: state => state.contactModule.contactProfileLoader,
        }),

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;
            vm.getContactReward(vm.params);
        },

        methods: {
            ...mapActions({
                getContactReward: 'contactModule/getContactReward',
                editReward: 'contactModule/editReward',
                redeemPoints: 'contactModule/redeemPoints',
                deleteReward: 'contactModule/deleteReward',
            }),

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactReward(vm.params);
            },

            actionHandler (reward, action) {
                const vm = this;

                vm.editForm.id = reward.id;
                vm.editForm.points = reward.points;
                vm.rewardEdit = true;
            },

            handleEditReward (reward, { setFieldError }) {
                const vm = this;

                vm.editForm.setFieldError = setFieldError;
                vm.editReward(vm.editForm).then((result) => {
                    vm.getContactReward(vm.params);
                    vm.rewardEdit = false;
                });
            },

            hendleDeleteReward () {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these reward!', 'Delete')

                option.preConfirm = function() {
                    return vm.deleteReward({ id: vm.selectedRewards }).then((result) => {
                        vm.selectedRewards = [];
                        vm.getContactReward(vm.params);
                    });
                };
                Swal.fire(option);
            },
        }
    }
</script>
<style scoped>
    .profile_notes .title h3{
        align-items: center;
        flex-grow: 1;
    }
    :deep(.form_content .action_wpr) {
        position: sticky;
        bottom: 0;
        left: 0;
        background: #fff;
        border-top: 1px solid #e9e9e9;
        padding: 20px 30px;
        margin: auto -30px 0 -30px;
    }
    .notes_wpr{
        overflow-y: scroll;
        flex: 1;
    }
    .notes_wpr::-webkit-scrollbar {
        display: none;
    }
    /* .profile_notes .card_listing{
        max-height: calc(100vh - 228px);
        overflow-y: scroll;
        margin: 15px -15px;
        row-gap: 30px;
    } */
    .profile_notes .card_listing::-webkit-scrollbar {
        display: none;
    }
    .edit_modal .edit_section{
        max-width: 400px;
    }
    .edit_modal .edit_section .form_body .form_content{
        padding-bottom: 0;
    }
    @media(max-width: 767px){
        .profile_notes .title{
            flex-direction: column;
            align-items: flex-start;
            row-gap: 10px;
        }
    }
    @media(max-width: 600px){
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
        .edit_modal{
            padding: 10px !important;
        }
        .profile_notes .title{
            flex-wrap: wrap;
        }
        .profile_notes .title h3{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
            /* padding: 15px 20px; */
        }
    }
</style>
